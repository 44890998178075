import React, { useEffect } from "react"
import "./header.scss"
//import 'uikit/dist/css/uikit.css'


import BackgroundImage from "gatsby-background-image"
import CustomMarkdown from "../../utils/customMarkdown"
import GetImgUrl from "../../utils/customFunctions"
import { AiOutlineRight } from "react-icons/ai"
import { GatsbyImage } from "gatsby-plugin-image"
import Button from "../button/button"


const Header = ({ Title, Subtitle, FirstCTALabel, FirstCTAURL, Background, Image, MarkdownTitle, BackgroundColor }) => {

    var headerStyle;

    Background ? headerStyle = { backgroundImage: "url(" + Background + ")" } : headerStyle = ""

    useEffect(() => {
        if (Background && Background.fluid && Background.fluid.srcWebp) {
            const preloadLink = document.createElement("link");
            preloadLink.href = Background.fluid.srcWebp;
            preloadLink.rel = "preload";
            preloadLink.as = "image";
            document.head.appendChild(preloadLink);
        }
        // console.log("background", Background)
        // console.log("SecondaryImage", SecondaryImage) 

    }, [])


    switch (Image === null) {
        case false:
            return (
                <>
                    <BackgroundImage Tag="section" backgroundColor={"#040e18"} role="img" fluid={Background.fluid != null ? Background.fluid : ["rgba(0,0,0,0)"]}>

                        <div className={BackgroundColor ? `header-${BackgroundColor} normal-height` : "header-Blue normal-height"}>
                            <div className="header-container">
                                <div className="uk-child-width-1-2@s uk-grid-small uk-grid" uk-grid="true">



                                    <div className="header-text" id="header-txt">
                                        <div className="header-cont">

                                            {
                                                Title ? <h1 className="header-title">{Title}</h1> : ""
                                            }



                                            {
                                                Subtitle ?

                                                    <CustomMarkdown children={Subtitle} /> : ""
                                            }


                                            {
                                                FirstCTALabel ?
                                                    <div className="btns-container">
                                                        <Button
                                                            btnType="primary"
                                                            href={FirstCTAURL}
                                                            label={FirstCTALabel}
                                                            additionalClass={"no-margin-left"}
                                                        />
                                                    </div>
                                                    : ""
                                            }
                                        </div>
                                    </div>



                                    <div className="header-image">
                                        <GatsbyImage image={Image?.gatsbyImageData} alt="How Comparison works - Step 1" className="item-image"></GatsbyImage>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </BackgroundImage>

                </>
            )
        case true:
            return (
                <div className={BackgroundColor ? `header-${BackgroundColor} small-height` : "header-Blue small-height"}>
                    <div className="header-container">
                        <div className="header-content-no-image">
                            <div className="header-text-center">

                                {
                                    Title ? <h1 className="header-title">{Title}</h1> : ""
                                }
                                {
                                    Subtitle ? <CustomMarkdown children={Subtitle} /> : ""

                                }
                                {
                                    FirstCTALabel ?
                                        <div className="btns-container">
                                            <Button
                                                btnType="primary"
                                                href={FirstCTAURL}
                                                label={FirstCTALabel}
                                                additionalClass={"no-margin-left"}
                                            />
                                        </div>
                                        : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>

            )
        default:
            return ("switch went to default")
    }


}

export default Header