import React from "react"
import { useStaticQuery, graphql } from "gatsby"


export function GetImgUrl(localFile___NODE){

    const data = useStaticQuery(graphql`
        {
            allFile {
                edges {
                  node {
                      id
                      publicURL
                      childImageSharp{
                        fluid(quality: 90, maxWidth: 1900) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                        gatsbyImageData (
                          placeholder: BLURRED
                          formats: [AUTO, WEBP]
                        )
                      }
                  }
                }
            }
        }
    `)

    var imgUrl = {imgName:null, basicUrl: null, fluid: null, gatsbyImageData:null}

    data.allFile.edges.map((item, index) => {

      if(item.node.id === localFile___NODE){
        // if(item.node.publicURL.endsWith(".svg")){
        //   imgUrl.basicUrl = item.node.publicURL
        // } else {
        //   imgUrl.fluid = item.node.childImageSharp.fluid
        //   imgUrl.basicUrl = item.node.publicURL
        // }
        imgUrl.imgName = item.node.name?item.node.name:null
        imgUrl.basicUrl = item.node.publicURL?item.node.publicURL:null
        imgUrl.fluid = item.node.childImageSharp?item.node.childImageSharp.fluid:null
        imgUrl.gatsbyImageData = item.node.childImageSharp.gatsbyImageData||null
        // imgUrl.gatsbyImageData = item.node.childImageSharp?item.node.childImageSharp.gatsbyImageData:null

        // imgUrl = item.node.publicURL
      }
      return("")
      
    })
    // console.log(imgUrl)
    return(imgUrl)
  }


export default GetImgUrl;