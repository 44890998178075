import React from "react"
//import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import remarkDirective from "remark-directive"
import remarkDirectiveRehype from "remark-directive-rehype"
import gfm from "remark-gfm"

import "../styles/customMarkdown.scss"

import loadable from '@loadable/component'
const ReactMarkdown = loadable(() => import("react-markdown"))



const CustomMarkdown = ({ children, className, skipHtml }) => {

    // const YouTubeVideo = ({ id, children }) => (
    //     // <iframe
    //     //     src={'https://www.towerheating.com/wp-content/uploads/' + id + '.mp4'}
    //     //     width="560"
    //     //     height="315"
    //     //     style={{objectFit:'cover !important'}}
    //     // >
    //     //     {children}
    //     // </iframe>
    //     <video style={{ objectFit: 'cover !important', width: "650px", height: "350px" }} autoPlay={true} loop={true} muted>
    //         <source src={'https://www.towerheating.com/wp-content/uploads/' + id + '.mp4'}></source>
    //     </video >
    // )

    const CustomLink = ({ children, title, rel, target }) => (

        <a href={children} target={target} rel={rel}>
            {title}
        </a>
    )

    const CTA = ({ children, title, rel, target, color }) => (
       
            <div className={title.includes("Policy") || color.includes("home") ? "customMarkdown_nopadd" : "customMarkdown_cta"}>

                <a href={children} className={'customMarkdown_cta-link ' + color + '-btn'} rel={rel}>{title}</a>
            </div>

       
    )

    const CustomPhoneButton = ({ children, title, rel, color }) => (
        <div className={'customMarkdown-phone-btn ' + color}>
            <a href={'tel:' + children} className={'customMarkdown-phone-btn-link '} rel={rel}>
                
                    {title}
                    
                {/* <div className="customMarkdown-phone-btn-phone">{children}</div> */}
            </a>
        </div>
    )

    const ReadMoreTruncate = ({ children }) => (
        <p>{children}...<span>find out more</span></p>
    )

    return (
        <>
            <ReactMarkdown
                children={children}
                remarkPlugins={[remarkDirective, remarkDirectiveRehype]}
                rehypePlugins={[rehypeRaw]}
                components={{
                    // 'youtube-video': YouTubeVideo,
                    'custom-link': CustomLink,
                    'custom-phone-btn': CustomPhoneButton,
                    'read-more-truncate': ReadMoreTruncate,
                    'cta': CTA,
                }}
                className={className}
                skipHtml={skipHtml}


            />
        </>
    )
}

export default CustomMarkdown